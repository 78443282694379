import UniversalProductCard from '@/components/universalParts/UniversalProductCard'
import useGetProductsBySearchTecdoc from '@/hooks/useGetProductsBySearchTecdoc'
import { Alert, Button, Col, Input, Row, Switch, Typography } from 'antd'
import React, { useEffect } from 'react'
import { Pagination, Select } from 'antd';
import UniversalProductSkeletonCard from '@/components/universalParts/UniversalProductSkeletonCard';
import { useTranslation } from 'react-i18next';




const UniversalParts = () => {

  const [searchTerm, setSearchTerm] = React.useState('')

  const [currentPage, setCurrentPage] = React.useState(1)

  const [pageSize, setPageSize] = React.useState(12)

  const useSearch = useGetProductsBySearchTecdoc();

  const { t } = useTranslation()

  const onOk = () => {
    useSearch.search(searchTerm, currentPage, pageSize)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    useSearch.search(searchTerm, page, pageSize)
  }

  const onShowSizeChange = (size) => {
    setPageSize(size)
    setCurrentPage(1)
    useSearch.search(searchTerm, 1, size)
  }

  useEffect(() => {
    onOk()
  }, [])

  return (
    <div>

      <Alert banner type='warning' message={t('beta-message', { version: '1.0.0' })} ></Alert>

      <Row className='mt-5' gutter={16}>
        <Col span={24}>
          <Typography.Title className='text-center' level={1}>{t('universal-parts')}</Typography.Title>
        </Col>

        <Col span={4}>
          <Select size='large' className='w-full' value={pageSize} onChange={onShowSizeChange}>
            <Select.Option value="12">{t('page-size-12')}</Select.Option>
            <Select.Option value="24">{t('page-size-24')}</Select.Option>
            <Select.Option value="50">{t('page-size-50')}</Select.Option>
            <Select.Option value="100">{t('page-size-100')}</Select.Option>
          </Select>
        </Col>

        <Col span={20}>
          <Input.Search
            loading={useSearch.loading}
            onKeyDown={() => {
              if (window.event.keyCode == 13) {
                onOk()
              }
            }} 
            enterButton={t('search')} 
            size='large' 
            placeholder={t('search-placeholder')} 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
        </Col>

        <div className='mt-[20px]'>
          <Pagination
            disabled={useSearch.loading}
            showSizeChanger={false}
            style={{ display: 'flex', justifyContent: 'space-between' }}
            current={currentPage}
            pageSize={pageSize}
            total={useSearch.total}
            onChange={handlePageChange}
            showTotal={() => t('total-items', { total: useSearch.total })}
          />
        </div>
      </Row>

      <div className='mt-50'>
        <Row gutter={16}>
          {useSearch.loading ? Array(12).fill().map((_, i) => (
            <Col className='mt-20' lg={8}>   <UniversalProductSkeletonCard key={i} /> </Col>
          )) : useSearch.products.map((product) => (
            <Col className='mt-20' lg={8}>   <UniversalProductCard deliveries={useSearch.deliveries} key={product.id} product={product} /> </Col>
          ))
          }
        </Row>
      </div>
    </div>
  )
}


export default UniversalParts